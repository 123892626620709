import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
   <svg xmlns="http://www.w3.org/2000/svg"   width="100%" height="100%" viewBox="0 0 1080.000000 1047.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g transform="translate(0.000000,1047.000000) scale(0.100000,-0.100000)"
fill={PRIMARY_MAIN}  stroke="none" strokeWidth="1"  fillRule="evenodd">
<path d="M5178 7323 c-10 -2 -18 -8 -18 -13 0 -5 -18 -14 -40 -20 -22 -6 -40
-17 -40 -24 0 -14 -21 -26 -47 -26 -8 0 -40 -27 -70 -60 -31 -33 -62 -60 -69
-60 -9 0 -14 -12 -14 -33 0 -26 -6 -36 -25 -45 -14 -6 -25 -17 -25 -23 0 -7
-7 -21 -16 -30 -18 -20 -47 -167 -47 -239 0 -63 22 -193 33 -200 6 -3 17 -28
25 -56 8 -27 31 -66 50 -88 19 -21 35 -42 35 -47 0 -5 10 -14 23 -20 12 -5 33
-26 46 -45 13 -18 32 -34 42 -34 9 0 24 -7 33 -15 8 -8 17 -15 21 -15 3 0 18
-8 32 -17 41 -27 150 -55 233 -60 62 -4 89 0 154 21 44 15 93 29 109 31 15 3
39 16 52 28 13 12 40 33 60 47 45 30 62 46 97 91 56 69 88 125 88 152 0 15 4
27 8 27 11 0 31 89 37 164 5 62 -19 226 -34 235 -4 4 -11 19 -15 35 -4 17 -18
40 -32 53 -15 14 -24 33 -24 53 0 19 -5 30 -14 30 -8 0 -30 19 -50 41 -37 44
-87 79 -111 79 -7 0 -20 9 -27 19 -8 11 -27 23 -43 27 -17 4 -38 14 -49 23
-15 13 -47 16 -185 17 -91 1 -174 0 -183 -3z m202 -49 c0 -2 -9 -4 -21 -4 -11
0 -18 4 -14 10 5 8 35 3 35 -6z m-124 -21 l25 -28 -28 -7 c-15 -4 -45 -5 -65
-1 -31 4 -36 8 -27 19 6 8 16 14 21 14 5 0 16 7 24 15 20 20 21 20 50 -12z
m264 -23 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15 -4 15
-10z m95 0 c3 -5 -3 -10 -14 -10 -12 0 -21 5 -21 10 0 6 6 10 14 10 8 0 18 -4
21 -10z m-58 -6 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-206
-68 c5 3 9 1 9 -4 0 -5 -19 -10 -42 -10 l-43 0 30 12 c17 7 32 9 33 4 2 -4 8
-6 13 -2z m197 -17 c-18 -12 -46 -11 -53 1 -9 15 37 30 53 17 11 -8 11 -12 0
-18z m132 10 c0 -11 -4 -18 -10 -14 -5 3 -7 12 -3 20 7 21 13 19 13 -6z m41
-101 c3 -4 6 -28 8 -53 2 -39 -1 -45 -18 -45 -15 0 -22 10 -29 43 -11 54 -5
78 16 70 10 -4 20 -10 23 -15z m52 -133 c18 -25 17 -72 -2 -67 -6 1 -11 -4
-11 -10 -1 -16 -29 33 -30 50 0 7 4 11 9 8 4 -3 6 6 3 19 -6 32 8 31 31 0z
m121 9 c14 -35 1 -64 -29 -64 -6 0 -13 54 -8 67 7 18 30 16 37 -3z m-131 -509
c4 -8 2 -17 -3 -20 -6 -4 -10 3 -10 14 0 25 6 27 13 6z m-87 -72 c-10 -10 -19
5 -10 18 6 11 8 11 12 0 2 -7 1 -15 -2 -18z"/>
<path d="M4275 6687 c-11 -8 -33 -17 -50 -21 -47 -11 -138 -49 -164 -68 -13
-10 -32 -18 -42 -18 -23 0 -107 -43 -214 -109 -22 -14 -49 -33 -60 -42 -11 -9
-30 -20 -42 -23 -12 -4 -38 -23 -58 -42 -19 -19 -40 -34 -47 -34 -23 0 -313
-277 -397 -379 -29 -35 -59 -71 -67 -78 -8 -8 -14 -20 -14 -28 0 -7 -4 -15 -9
-17 -11 -4 -98 -132 -116 -172 -8 -17 -35 -67 -60 -111 -24 -44 -49 -91 -56
-105 -6 -14 -19 -42 -30 -62 -10 -20 -19 -45 -19 -55 0 -10 -7 -28 -15 -40 -8
-12 -20 -45 -26 -74 -7 -30 -17 -63 -24 -74 -7 -11 -16 -44 -19 -73 -4 -30
-11 -61 -16 -70 -6 -10 -21 -87 -35 -172 -22 -133 -25 -186 -25 -385 0 -126 5
-248 10 -270 6 -22 15 -74 20 -115 6 -41 17 -95 24 -120 8 -25 21 -72 29 -105
23 -94 47 -178 55 -189 4 -6 18 -38 30 -71 51 -134 162 -335 262 -475 25 -34
53 -74 62 -88 9 -13 20 -28 25 -31 4 -3 41 -45 82 -94 61 -72 77 -86 95 -81
25 7 128 106 146 139 11 21 9 27 -26 62 -76 77 -201 240 -260 338 -51 85 -147
270 -175 340 -64 159 -117 364 -141 545 -14 115 -4 502 16 573 9 32 16 74 16
93 0 19 9 60 20 91 11 31 23 78 26 105 4 26 13 53 20 59 8 6 14 21 14 33 0 11
9 36 20 54 11 17 25 52 31 77 6 25 15 45 19 45 4 0 11 12 15 28 3 15 12 34 20
42 7 8 18 32 25 53 6 20 15 37 19 37 4 0 15 15 24 33 10 17 26 46 37 62 11 17
27 43 36 59 9 16 25 38 35 49 10 12 19 24 19 29 0 4 9 18 20 30 11 13 48 55
82 93 88 101 132 147 159 170 14 11 52 42 84 70 33 27 66 54 73 60 7 5 21 17
31 26 16 14 64 47 126 87 11 6 31 18 45 24 14 7 28 18 32 24 4 7 20 15 35 19
14 3 38 15 51 26 14 10 29 19 35 19 6 0 47 17 91 39 45 21 102 44 129 51 26 7
50 17 53 21 3 5 16 9 29 9 30 0 39 27 19 58 -9 14 -19 48 -23 76 l-6 51 -45 3
c-26 2 -53 -3 -65 -11z"/>
<path d="M6293 6383 c-9 -16 -32 -46 -50 -69 -40 -50 -41 -66 -5 -90 15 -10
33 -22 39 -28 7 -6 23 -19 35 -28 126 -97 172 -136 223 -191 96 -102 125 -136
125 -146 0 -6 6 -14 14 -18 22 -12 116 -134 116 -149 0 -8 4 -14 9 -14 10 0
47 -57 69 -105 7 -16 16 -32 20 -35 7 -5 29 -48 80 -161 l31 -66 62 -7 c85 -8
157 -8 163 2 7 11 -14 92 -24 92 -4 0 -13 20 -19 44 -6 24 -17 49 -25 55 -8 7
-18 25 -21 41 -3 16 -13 34 -21 41 -8 6 -14 17 -14 25 0 7 -11 25 -25 40 -14
15 -25 35 -25 45 0 9 -11 30 -23 46 -13 15 -36 49 -51 76 -16 26 -32 47 -37
47 -5 0 -9 6 -9 14 0 8 -6 21 -14 29 -8 7 -38 43 -66 78 -68 83 -119 131 -131
123 -6 -3 -7 4 -3 20 5 23 3 26 -20 26 -19 0 -31 10 -48 40 -13 22 -28 40 -35
40 -6 0 -33 20 -60 45 -26 25 -66 58 -88 73 -22 15 -44 33 -48 39 -4 7 -13 13
-20 13 -7 0 -23 9 -37 20 -34 26 -46 25 -67 -7z m122 -103 c3 -5 1 -10 -4 -10
-6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
<path d="M4890 5789 c-73 -4 -129 -12 -152 -23 -21 -9 -52 -16 -70 -16 -18 0
-46 -9 -63 -20 -16 -11 -39 -20 -50 -20 -21 0 -201 -87 -226 -109 -8 -6 -57
-42 -110 -79 -53 -37 -118 -92 -144 -122 -27 -30 -52 -56 -56 -58 -5 -2 -37
-42 -73 -90 -79 -107 -159 -257 -142 -270 14 -12 74 -39 106 -47 14 -4 33 -14
43 -23 24 -21 64 -2 73 35 25 98 227 340 358 428 79 54 256 144 284 144 13 1
37 8 54 17 99 51 531 46 636 -7 73 -38 122 -19 122 46 0 14 9 38 19 53 11 15
21 38 23 52 3 21 -2 25 -36 31 -21 3 -41 11 -45 17 -3 5 -29 15 -56 21 -28 5
-73 16 -100 24 -60 17 -235 24 -395 16z"/>
<path d="M5692 5580 c-98 -42 -95 -191 6 -243 49 -25 90 -20 142 17 l45 33 3
69 c3 68 2 71 -30 99 -26 23 -43 30 -88 32 -30 2 -65 -2 -78 -7z"/>
<path d="M4872 4887 c-9 -11 -12 -134 -10 -523 l3 -509 29 -5 c16 -3 59 -3 95
0 l66 5 3 155 c3 191 0 187 121 180 68 -4 85 -9 97 -25 8 -11 27 -33 43 -50
15 -16 52 -59 83 -95 30 -36 77 -89 105 -118 l49 -53 109 3 c67 2 110 7 112
14 3 9 -58 82 -147 175 -14 14 -45 50 -70 80 -24 30 -48 56 -52 57 -5 2 -8 12
-8 21 0 12 21 29 65 50 70 35 118 86 151 161 29 67 27 217 -4 285 -28 62 -101
131 -178 170 l-59 30 -295 4 c-251 3 -298 1 -308 -12z m583 -196 c65 -37 68
-43 73 -135 l4 -84 -40 -44 -41 -43 -181 -3 c-132 -3 -185 -1 -195 8 -20 17
-22 299 -2 319 9 10 52 12 177 9 150 -4 169 -6 205 -27z"/>
<path d="M6367 4885 c-16 -15 -17 -61 -17 -520 0 -500 0 -504 21 -515 24 -13
158 -5 173 10 6 6 10 64 9 133 0 67 4 130 9 140 8 15 26 17 166 17 187 0 270
18 355 74 56 37 71 55 116 136 21 39 25 61 29 157 4 122 1 135 -66 239 -25 38
-111 102 -170 126 -17 7 -138 13 -318 16 -263 4 -292 3 -307 -13z m559 -191
c56 -21 92 -69 104 -137 11 -57 -4 -122 -34 -153 -9 -8 -19 -22 -24 -30 -14
-25 -77 -34 -239 -34 -126 0 -154 3 -168 16 -14 14 -15 38 -10 178 4 118 9
164 18 171 23 17 303 8 353 -11z"/>
<path d="M8210 4890 c-36 -3 -79 -13 -95 -22 -17 -9 -43 -22 -59 -29 -56 -27
-114 -97 -136 -165 -26 -81 -25 -98 6 -184 29 -77 65 -113 154 -157 52 -25
185 -58 280 -68 75 -8 142 -36 176 -72 19 -20 24 -37 24 -77 0 -62 -15 -87
-77 -122 -42 -25 -54 -27 -162 -27 -113 -1 -117 0 -174 32 -32 19 -69 48 -83
67 -34 44 -54 49 -106 24 -71 -34 -90 -53 -83 -81 8 -32 109 -146 138 -155 12
-4 33 -15 47 -25 57 -41 269 -59 398 -34 142 28 223 84 277 190 36 72 36 189
-1 269 -23 52 -103 136 -129 136 -5 0 -32 9 -60 19 -55 21 -123 35 -263 56
-76 11 -99 19 -131 44 -51 41 -62 89 -31 134 34 50 66 68 133 77 111 16 198
-6 295 -75 25 -18 39 -22 61 -15 42 12 111 58 111 74 0 35 -74 106 -152 146
-86 45 -196 57 -358 40z"/>
<path d="M3701 4739 c-46 -18 -111 -81 -136 -130 -33 -65 -33 -124 1 -192 43
-86 138 -147 227 -147 44 0 64 9 130 59 56 42 97 120 97 186 0 43 -44 155 -60
155 -5 0 -23 13 -40 29 -51 46 -156 65 -219 40z"/>
<path d="M3811 4106 c-36 -13 -67 -28 -69 -34 -2 -6 6 -32 17 -58 12 -26 21
-51 21 -56 0 -11 61 -140 69 -148 8 -7 31 -47 53 -92 10 -21 22 -38 26 -38 4
0 15 -12 24 -27 17 -28 50 -66 131 -151 102 -108 409 -297 513 -317 20 -3 50
-12 68 -20 46 -20 292 -55 388 -55 46 0 99 5 118 10 19 6 69 15 110 21 67 9
180 39 220 59 20 10 18 75 -3 98 -8 9 -18 34 -22 55 -10 53 -45 76 -89 58 -17
-7 -51 -18 -76 -23 -25 -5 -73 -16 -107 -24 -100 -23 -261 -18 -404 12 -68 14
-134 32 -146 40 -12 8 -27 14 -34 14 -30 0 -211 103 -284 162 -85 69 -198 195
-246 273 -43 71 -48 80 -60 105 -6 14 -18 41 -27 60 -9 19 -19 49 -22 65 -5
28 -8 30 -55 32 -30 1 -75 -7 -114 -21z"/>
<path d="M5644 3542 c-24 -19 -54 -73 -54 -96 0 -35 32 -92 61 -108 65 -38
144 -7 175 68 11 27 11 38 -5 79 -10 27 -27 54 -37 62 -26 19 -114 16 -140 -5z"/>
<path d="M6930 3470 c-11 -3 -26 -15 -33 -26 -33 -57 -59 -101 -75 -125 -61
-93 -115 -169 -142 -199 -17 -19 -50 -57 -74 -85 -123 -141 -201 -208 -386
-332 -30 -21 -61 -43 -68 -50 -7 -7 -18 -13 -23 -13 -5 0 -32 -15 -61 -33 -60
-37 -154 -82 -243 -116 -33 -13 -73 -29 -90 -36 -33 -13 -182 -55 -288 -80
-204 -47 -632 -47 -840 0 -176 41 -335 92 -404 131 -40 22 -79 6 -88 -37 -4
-17 -18 -54 -32 -81 -38 -75 -30 -88 74 -127 49 -18 109 -41 135 -52 26 -10
55 -19 66 -19 11 0 39 -7 63 -15 24 -9 103 -28 174 -43 125 -25 143 -26 440
-26 333 0 295 -4 555 59 126 30 253 70 296 93 10 6 38 15 62 21 23 7 42 16 42
21 0 6 5 10 12 10 13 0 111 48 183 90 28 16 61 35 75 42 14 7 43 25 65 41 22
15 65 44 95 65 30 20 62 44 70 52 9 8 40 35 69 60 73 62 241 228 271 267 14
18 32 42 41 52 46 57 139 191 150 214 5 12 28 51 50 87 52 85 91 172 82 181
-9 9 -193 16 -223 9z"/>
<path d="M3671 2850 c-93 -20 -164 -99 -181 -202 -17 -109 24 -203 114 -261
68 -43 167 -50 244 -17 71 31 124 96 141 172 34 158 -64 301 -215 312 -32 3
-79 1 -103 -4z"/>
<path d="M735 10 c-27 -5 -6 -7 70 -7 83 -1 100 1 70 8 -46 9 -84 9 -140 -1z"/>
</g>
      </svg>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
